<template>
  <v-card
    class="pa-3 d-flex"
    style="flex-direction: column; gap: 12px; border-radius: 8px"
    flat
    outlined
  >
    <v-card-actions class="pa-0 subtitle-2" style="height: 20px">
      <v-btn @click="printer.select = !printer.select" icon x-small>
        <v-icon class="mx-auto" size="16" color="primary">
          {{ printer.select ? "mdi-check-circle" : "mdi-circle-outline" }}
        </v-icon>
      </v-btn>
      <span class="ml-2" :class="getStatus().class">
        {{ getStatus().text }}
      </span>
      <v-spacer />
      <v-btn icon x-small @click="openDialog">
        <v-icon size="16"> mdi-open-in-new </v-icon>
      </v-btn>
    </v-card-actions>
    <v-img
      :src="
        printer.getStat && printer.getStat.snapshot
          ? `data:image/jpeg;base64,${printer.getStat.snapshot}`
          : ''
      "
      :aspect-ratio="1280 / 720"
      style="border-radius: 4px; position: relative"
    >
      <template v-slot:placeholder>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
          "
        >
          <v-progress-circular indeterminate color="background_dark" />
        </div>
      </template>
    </v-img>

    <v-card-title
      class="pa-0 subtitle-2 d-flex"
      style="flex-direction: column; gap: 12px; width: 100%"
    >
      <div style="width: 100%">
        {{ printer.makerSpace }} - {{ printer.displayName }}
      </div>
      <div class="d-flex" style="width: 100%">
        <div>
          <v-icon size="14" color="black" class="mr-1">
            mdi-printer-3d-nozzle-heat
          </v-icon>
          {{
            printer.getStat ? Math.floor(printer.getStat.nozzle_temp) : "000"
          }}
          /
          {{ printer.getStat ? printer.getStat.nozzle_target : "000" }}
        </div>
        <v-spacer />
        <div>
          <v-icon size="14" color="black" class="mr-1"> mdi-radiator </v-icon>
          {{ printer.getStat ? Math.floor(printer.getStat.bed_temp) : "00" }}
          /
          {{ printer.getStat ? printer.getStat.bed_target : "00" }}
        </div>
      </div>
      <div style="width: 100%" class="d-flex">
        <v-icon size="14" color="black" class="mr-1"> mdi-file </v-icon>
        <div
          class="overflow-x-hidden"
          style="height: 15px; white-space: nowrap"
        >
          {{
            printer.getStat
              ? printer.getStat.filename.replace(/\.gcode$/, "")
              : "-"
          }}
        </div>
      </div>

      <v-progress-linear
        :value="
          getStatus().text == 'Complete'
            ? '100'
            : printer.getStat &&
              Math.floor(
                (printer.getStat.print_duration * 100) /
                  printer.getStat.estimated_time
              )
        "
        height="12"
        style="border-radius: 4px"
      />
      <div class="d-flex" style="width: 100%">
        <div v-if="getStatus().text == 'Complete'">
          걸린 시간 :
          {{
            printer.getStat
              ? Math.floor(printer.getStat.print_duration / 3600)
              : "00"
          }}시간
          {{
            printer.getStat
              ? Math.floor((printer.getStat.print_duration % 3600) / 60)
              : "00"
          }}분
        </div>
        <div v-else>
          남은 시간 :
          {{
            printer.getStat
              ? Math.floor(
                  (printer.getStat.estimated_time -
                    printer.getStat.print_duration) /
                    3600
                )
              : "00"
          }}시간
          {{
            printer.getStat
              ? Math.floor(
                  ((printer.getStat.estimated_time -
                    printer.getStat.print_duration) %
                    3600) /
                    60
                )
              : "00"
          }}분
        </div>
        <v-spacer />
        <div>
          {{
            getStatus().text == "Complete"
              ? "100"
              : printer.getStat
              ? Math.floor(
                  (printer.getStat.print_duration * 100) /
                    printer.getStat.estimated_time
                )
              : "00"
          }}%
        </div>
      </div>
    </v-card-title>
    <v-card-actions class="pa-0">
      <div class="d-flex justify-space-between" style="width: 100%">
        <v-btn
          v-for="(button, index) in currentButtonSet"
          :key="index"
          depressed
          class="mx-0 px-0"
          height="32px"
          :min-width="currentButtonSet.length == 1 ? '100%' : '30%'"
          style="border: 1px solid var(--v-background_dark-base)"
          @click="handleButtonClick(button.action)"
        >
          <v-icon :size="20">{{ button.icon }}</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="rounded-xl">
        <v-card-actions class="pa-4 pb-2">
          <v-btn
            color="error"
            class="rounded-lg font-weight-bold"
            depressed
            @click="dialogDelete = true"
            icon
          >
            <feather type="trash" />
          </v-btn>
          <!-- 삭제 누르면 뜨는 Dialog -->
          <v-dialog v-model="dialogDelete" max-width="280px">
            <v-card class="rounded-xl text-center pa-2" flat>
              <v-card-title class="title font-weight-bold">
                선택한 항목을 삭제하겠습니까?
              </v-card-title>

              <v-card-subtitle class="ma-0">
                해당 항목이 즉시 삭제됩니다. <br />
                이 동작은 취소할 수 없습니다.
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="surface rounded-lg"
                  depressed
                  @click="dialogDelete = false"
                >
                  취소
                </v-btn>
                <v-btn
                  color="error rounded-lg"
                  depressed
                  @click="deletePrinter()"
                >
                  삭제
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-btn
            rounded
            icon
            :disabled="!saveBtn"
            :color="saveBtn ? 'onSurface' : 'grey'"
            @click="updatePrinter()"
          >
            <feather type="save" />
          </v-btn>
          <v-btn rounded icon color="onSurface" @click="dialog = false">
            <feather type="x-circle" />
          </v-btn>
        </v-card-actions>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'현재 상태'"
          />
        </v-card-title>
        <v-card-text>
          <v-row class="pa-3">
            <v-col cols="6">
              <v-img
                :src="
                  printer.getStat && printer.getStat.snapshot
                    ? `data:image/jpeg;base64,${printer.getStat.snapshot}`
                    : ''
                "
                :aspect-ratio="1280 / 720"
                style="border-radius: 4px; position: relative"
              >
                <template v-slot:placeholder>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                      width: 100%;
                    "
                  >
                    <v-progress-circular
                      indeterminate
                      color="background_dark"
                    />
                  </div>
                </template>
              </v-img>
            </v-col>
            <v-col cols="6">
              <v-card-title
                class="pa-0 subtitle-2 d-flex"
                style="flex-direction: column; gap: 8px; width: 100%"
              >
                <div style="width: 100%" class="title-2">
                  {{ printer.makerSpace }} - {{ printer.displayName }}
                </div>
                <div class="justify-start" :class="getStatus().class">
                  {{ getStatus().text }}
                </div>
                <div class="d-flex" style="width: 100%">
                  <div>
                    <v-icon size="16" color="black" class="mr-1">
                      mdi-printer-3d-nozzle-heat
                    </v-icon>
                    {{
                      printer.getStat
                        ? Math.floor(printer.getStat.nozzle_temp)
                        : "000"
                    }}
                    /
                    {{
                      printer.getStat ? printer.getStat.nozzle_target : "000"
                    }}
                  </div>
                  <v-spacer />
                  <div>
                    <v-icon size="16" color="black" class="mr-1">
                      mdi-radiator
                    </v-icon>
                    {{
                      printer.getStat
                        ? Math.floor(printer.getStat.bed_temp)
                        : "00"
                    }}
                    /
                    {{ printer.getStat ? printer.getStat.bed_target : "00" }}
                  </div>
                </div>
                <div style="width: 100%">
                  <v-icon size="16" color="black" class="mr-1">
                    mdi-file
                  </v-icon>
                  {{ printer.getStat ? printer.getStat.filename : "-" }}
                </div>

                <v-progress-linear
                  :value="
                    printer.getStat &&
                    Math.floor(
                      (printer.getStat.print_duration * 100) /
                        printer.getStat.estimated_time
                    )
                  "
                  height="12"
                  style="border-radius: 4px"
                />
                <div class="d-flex" style="width: 100%">
                  <div>
                    남은 시간 :
                    {{
                      printer.getStat
                        ? Math.floor(
                            (printer.getStat.estimated_time -
                              printer.getStat.print_duration) /
                              3600
                          )
                        : "00"
                    }}시간
                    {{
                      printer.getStat
                        ? Math.floor(
                            ((printer.getStat.estimated_time -
                              printer.getStat.print_duration) %
                              3600) /
                              60
                          )
                        : "00"
                    }}분
                  </div>
                  <v-spacer />
                  <div>
                    {{
                      printer.getStat
                        ? Math.floor(
                            (printer.getStat.print_duration * 100) /
                              printer.getStat.estimated_time
                          )
                        : "00"
                    }}%
                  </div>
                </div>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'작업 대기열'"
          />
        </v-card-title>
        <v-card-text>
          <div
            class="pa-3 d-flex overflow-auto"
            style="
              flex-direction: column;
              height: 200px;
              border-radius: 4px;
              outline: 1px solid var(--v-onSurface_normal-base);
            "
          >
            <div v-for="(item, n) in printer.queue" :key="n" class="d-flex">
              <div>{{ item.filename }}</div>
              <v-spacer />
              <v-btn icon @click="deleteQueue(item.job_id)">
                <feather type="trash" />
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'파일 목록'"
          />
          <v-spacer />
          <v-btn @click="$refs.uploader.click()"> 업로드 </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="gcode"
            @change="onFileChanged"
          />
        </v-card-title>
        <v-card-text>
          <div
            class="pa-3 d-flex overflow-auto"
            style="
              flex-direction: column;
              height: 200px;
              border-radius: 4px;
              outline: 1px solid var(--v-onSurface_normal-base);
            "
          >
            <div v-for="(item, n) in printer.files" :key="n" class="d-flex">
              <div>{{ item.path }}</div>
              <v-spacer />
              <v-btn icon @click="addQueue(item.path)">
                <v-icon> mdi-plus-box-outline </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'매크로'"
          />
        </v-card-title>
        <v-card-text>
          <div class="pa-3 d-flex flex-wrap" style="gap: 8px">
            <v-btn
              v-for="item in buttonSets.all"
              :key="item.action"
              @click="handleButtonClick(item.action)"
              elevation="0"
              outlined
            >
              {{ item.action }}
            </v-btn>
          </div>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'Gcode 터미널'"
          />
        </v-card-title>
        <v-card-text>
          <div class="d-flex pa-3" style="flex-direction: column">
            <div
              class="d-flex overflow-auto mb-2"
              style="
                flex-direction: column;
                height: 200px;
                border-radius: 4px;
                outline: 1px solid var(--v-onSurface_normal-base);
              "
            >
              <div
                class="d-flex px-3"
                v-for="(item, n) in gcodeHistory"
                :key="n"
              >
                <v-icon> mdi-chevron-double-right </v-icon>
                <div>
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <v-text-field
                outlined
                v-model="gcode"
                dense
                class="mr-4"
                hide-details=""
                @keydown.enter="sendGcode(gcode)"
              />
              <v-btn
                @click="sendGcode(gcode)"
                elevation="0"
                outlined
                height="inherit"
              >
                Send
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'소프트웨어 업데이트'"
          />
        </v-card-title>
        <v-card-text>
          <div class="d-flex pa-3" style="width: 100%">
            <div class="d-flex" style="flex-direction: column">
              <div class="title-2">MA3D</div>
              <div class="ml-2 subtitle-2">
                {{
                  (printer.update ? printer.update.version : "v0.0.0") +
                  "&nbsp; > &nbsp;" +
                  (printer.update ? printer.update.remote_version : "v0.0.0")
                }}
              </div>
            </div>
            <v-spacer />
            <div>
              <v-btn
                @click="update()"
                class="mr-5"
                color="
                  primary
                "
                v-if="
                  printer.update &&
                  printer.update.version != printer.update.remote_version
                "
              >
                업데이트
              </v-btn>
              <v-btn @click="check_update()" icon>
                <v-icon> mdi-refresh </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-title>
          <span
            class="highlight title-1 font-weight-bold onSurface--text"
            v-text="'기본 정보'"
          />
        </v-card-title>
        <v-card-text>
          <div class="d-flex pa-3" style="flex-direction: column; gap: 16px">
            <v-text-field
              v-model="selectedPrinter.id"
              label="ID"
              readonly
              outlined
              dense
              hide-details
            />
            <v-text-field
              v-model="selectedPrinter.makerSpace"
              label="메이커스페이스"
              outlined
              dense
              hide-details
            />
            <v-text-field
              v-model="selectedPrinter.displayName"
              label="이름"
              outlined
              dense
              hide-details
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import firestorageUtils from "@/utils/firestorage";
import _ from "lodash";
export default {
  name: "Printer-Card",
  props: { printer: Object },
  data() {
    return {
      selectedPrinter: {},

      dialog: false,
      dialogDelete: false,
      saveBtn: false,

      buttonSets: {
        Printing: [
          {
            icon: "mdi-play-pause",
            action: "pause_resume",
          },
          { icon: "mdi-stop", action: "abort" },
          {
            icon: "mdi-car-brake-alert",
            action: "emergency",
          },
        ],
        Ready: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Complete: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Paused: [
          {
            icon: "mdi-play-pause",
            action: "pause_resume",
          },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Cancelled: [
          { icon: "mdi-fast-forward", action: "print" },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
        ],
        Shutdown: [
          {
            icon: "mdi-refresh",
            action: "Firmware_Restart",
          },
        ],
        all: [
          { icon: "mdi-fast-forward", action: "print" },
          {
            icon: "mdi-play-pause",
            action: "pause_resume",
          },
          { icon: "mdi-stop", action: "abort" },
          {
            icon: "mdi-car-brake-alert",
            action: "emergency",
          },
          { icon: "mdi-progress-upload", action: "unload" },
          { icon: "mdi-progress-download", action: "load" },
          { icon: "", action: "G28" },
          { icon: "", action: "HOME_Z" },
          { icon: "", action: "Firmware_Restart" },
        ],
      },
      gcode: "",
      gcodeHistory: [],
      gcodeFile: null,
    };
  },
  watch: {
    selectedPrinter: {
      handler() {
        _.isEqual(this.printer, this.selectedPrinter)
          ? (this.saveBtn = false)
          : (this.saveBtn = true);
      },
      deep: true,
    },
    dialog: {
      handler(val) {
        if (val) {
          this.check_update();
          this.check_file();
          this.check_queue();
        }
      },
    },
  },
  computed: {
    currentButtonSet() {
      return this.buttonSets[this.getStatus().text] || [];
    },
  },
  methods: {
    getStatus() {
      const res = { class: "", text: "" };
      switch (this.printer.getStat && this.printer.getStat.status) {
        case "standby":
          res.class = "onSurface_dark--text";
          res.text = "Ready";
          break;
        case "printing":
          res.class = "primary--text";
          res.text = "Printing";
          break;
        case "complete":
          res.class = "success--text";
          res.text = "Complete";
          break;
        case "cancelled":
          res.class = "warning--text";
          res.text = "Cancelled";
          break;
        case "shutdown":
          res.class = "error--text";
          res.text = "Shutdown";
          break;
        case "paused":
          res.class = "warning--text";
          res.text = "Paused";
          break;
        default:
          res.class = "background_dark--text";
          res.text = "Not Connected";
          break;
      }
      return res;
    },
    handleButtonClick(action) {
      this[action]();
    },
    pause_resume() {
      if (this.getStatus().text == "Paused") {
        this.gcodeHistory.push("RESUME");
        this.$emit("publishToTopic", {
          printer: this.printer,
          topic: `/POST/printer/gcode/script?script=RESUME`,
          message: {},
        });
      } else {
        this.gcodeHistory.push("PAUSE");
        this.$emit("publishToTopic", {
          printer: this.printer,
          topic: `/POST/printer/gcode/script?script=PAUSE`,
          message: {},
        });
      }
    },
    abort() {
      this.gcodeHistory.push("CANCEL");
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/printer/print/cancel`,
        message: {},
      });
    },
    emergency() {
      this.gcodeHistory.push("Emergency_stop");
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/printer/emergency_stop`,
        message: {},
      });
    },
    print() {
      this.gcodeHistory.push("Print Queue");
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/server/job_queue/start`,
        message: {},
      });
    },
    sendGcode() {
      this.gcodeHistory.push(this.gcode);
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/printer/gcode/script?script=${this.gcode}`,
        message: {},
      });
      this.gcode = "";
    },
    G28() {
      this.gcode = "G28";
      this.sendGcode();
    },
    HOME_Z() {
      this.gcode = "HOME_Z";
      this.sendGcode();
    },
    Firmware_Restart() {
      this.gcodeHistory.push("FIRMWARE_RESTART");
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/printer/firmware_restart`,
        message: {},
      });
    },
    check_update() {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/machine/update/refresh?name=ma3d`,
        message: {},
      });
    },
    check_file() {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/GET/server/files/list`,
        message: {},
      });
    },
    async onFileChanged(e) {
      // 파일 선택
      const file = e.target.files[0];
      if (!file) return;
      console.log(file);

      // 파일 확장자 검증
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      if (fileExtension !== "gcode") {
        return this.$toasted.global.error("gcode 파일만 가능합니다");
      }

      // 파일 메타데이터 Firestore에 저장
      const uploader = this.$store.getters["auth/user/GET_USER"].displayName;
      const metadataResponse = await this.$axios.put("/printer/gcode", {
        uploader: uploader,
        filename: fileName,
      });
      const dbId = metadataResponse.data.id;
      // 파일 Storage에 업로드
      const storagePath = `Gcodes/${dbId}/${fileName}`;
      console.log(storagePath);
      const uploadResult = await firestorageUtils.uploadFile({
        file: file,
        path: storagePath,
      });

      // 업로드 후 Firestore 메타데이터 업데이트
      await this.$axios.post(`/printer/gcode/${dbId}`, {
        filePath: storagePath,
        downloadUrl: uploadResult.downloadURL,
      });

      this.upload_file(fileName, uploadResult.downloadURL);
    },
    upload_file(filename, url) {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/CUSTOM/uploadFile`,
        message: {
          filename,
          url,
        },
      });
    },
    addQueue(file) {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/server/job_queue/job?filenames=${file}`,
        message: {},
      });
    },
    deleteQueue(file) {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/DELETE/server/job_queue/job?job_ids=${file}`,
        message: {},
      });
    },
    check_queue() {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/GET/server/job_queue/status`,
        message: {},
      });
    },
    update() {
      this.$emit("publishToTopic", {
        printer: this.printer,
        topic: `/POST/machine/update/client?name=ma3d`,
        message: {},
      });
    },
    unload() {
      this.gcode = "UNLOAD";
      this.sendGcode();
    },
    load() {
      this.gcode = "LOAD";
      this.sendGcode();
    },
    openDialog() {
      this.selectedPrinter = Object.assign({}, this.printer);
      this.dialog = true;
    },
    updatePrinter() {
      this.$axios
        .post("/printer", this.selectedPrinter)
        .catch((error) => {
          console.error("Error updating printer:", error);
        })
        .then(() => {
          this.$emit("refresh");
          this.dialog = false; // Dialog 숨기기
        });
    },
    deletePrinter() {
      this.$axios
        .delete(`/printer/${this.selectedPrinter.id}`)
        .catch((error) => {
          console.error("Error deleting printer:", error);
        })
        .then(() => {
          this.$emit("refresh");
          this.dialog = false; // Dialog 숨기기
        });
    },
  },
};
</script>

<style></style>
