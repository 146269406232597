<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent" flat>
          <v-card-title class="title font-weight-bold">
            프린터 대시보드
          </v-card-title>
          <v-card-subtitle>
            메이드올의 모든 프린터를 한곳에서 확인할 수 있습니다.
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card class="pa-4 rounded-lg" outlined>
          <v-card-actions class="pb-4">
            <div class="d-flex mb-auto" style="flex-direction: column">
              <div>총 장비 수 : {{ printers.length }} 대</div>
              <div>
                - 메이드올 대구지사 :
                {{
                  printers.filter((e) => e.makerSpace === "메이드올 대구지사")
                    .length
                }}
                대
              </div>
              <div>
                - 경희대학교 국제캠퍼스 :
                {{
                  printers.filter(
                    (e) => e.makerSpace === "경희대학교 국제캠퍼스"
                  ).length
                }}
                대
              </div>
            </div>
            <v-spacer />
            <div class="d-flex" style="flex-direction: column; gap: 12px">
              <div class="d-flex">
                <v-autocomplete
                  v-model="makerSpace"
                  :items="makerSpaceList"
                  cache-items
                  hide-no-data
                  hide-details
                  style="max-width: 240px"
                  clearable
                  outlined
                  dense
                  class="font-weight-regular"
                  label="시설을 선택하세요"
                />
                <v-btn
                  icon
                  @click="refresh"
                  :disabled="fetchLoading"
                  class="ml-4"
                >
                  <v-progress-circular
                    indeterminate
                    v-if="fetchLoading"
                    size="20"
                    width="3"
                    color="primary"
                  />
                  <v-icon v-else> mdi-refresh </v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-end">
                <v-btn color="primary" @click="addPrinter()">
                  프린터 추가
                </v-btn>
              </div>
            </div>
          </v-card-actions>
          <v-card-text class="pa-2">
            <div
              v-if="printers.length == 0"
              class="py-10 justify-center"
              style="width: 100%"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                "
                class="mx-auto"
              >
                <v-progress-circular
                  indeterminate
                  color="background_dark"
                  size="120"
                  width="8"
                />
                <div class="mt-4">
                  {{ loadingMsg }}
                </div>
              </div>
            </div>
            <v-row v-else class="px-2">
              <v-col
                lg="2"
                md="4"
                sm="6"
                cols="12"
                v-for="printer in printers"
                :key="printer.id"
                class="pa-1"
              >
                <printerCard
                  :printer="printer"
                  @refresh="makerSpaceSearch"
                  @publishToTopic="publishToTopic"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";
// AWS IoT Device SDK 및 AWS SDK를 불러옵니다.
import AWS from "aws-sdk";
import { mqtt, iot } from "aws-iot-device-sdk-v2";
import printerCard from "./PrinterCard.vue";

export default {
  name: "Admin-Printer",
  components: { printerCard },
  data() {
    return {
      refreshEnabled: false,
      loadingMsg: "",

      AWSConnection: null,
      makerSpace: null,
      makerSpaceList: ["메이드올 대구지사", "경희대학교 국제캠퍼스"],
      fetchLoading: false,

      subscription: [],
      printers: [],
    };
  },
  beforeCreate() {
    setMeta({
      title: "프린터 대시보드",
      description: "전체 프린터 운용 현황 보기",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  async mounted() {
    await this.connectToAwsIot();
    this.makerSpaceSearch();
  },
  beforeDestroy() {
    this.refreshEnabled = false;
    this.unsubscribeExistingSubscriptions();
  },
  watch: {
    makerSpace(n, o) {
      if (n !== o) this.makerSpaceSearch();
    },
  },

  methods: {
    async connectToAwsIot() {
      this.loadingMsg = "Connecting to AWS...";
      if (this.AWSConnection) {
        console.log("Already connected to AWS IoT.");
        return;
      }
      // AWS CognitoIdentityCredentials 설정
      AWS.config.region = process.env.VUE_APP_AWS_REGION; // 설정 파일에 정의된 리전 정보
      const credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID, // 설정 파일에 정의된 아이덴티티 풀 ID
      });
      AWS.config.credentials = credentials;
      await this.refreshAwsCredentials(credentials);

      const awsCredentials = await this.getAwsCredentials(credentials);
      this.AWSConnection = await this.connectWebsocket(awsCredentials);
    },
    async refreshAwsCredentials(credentials) {
      return new Promise((resolve, reject) => {
        credentials.get((error) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
      });
    },
    async getAwsCredentials(credentials) {
      return {
        aws_region: AWS.config.region,
        aws_access_key_id: credentials.accessKeyId,
        aws_secret_access_key: credentials.secretAccessKey,
        aws_session_token: credentials.sessionToken,
      };
    },
    async connectWebsocket(awsCredentials) {
      const config =
        iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
          .with_clean_session(true)
          .with_client_id(`test-${Math.floor(Math.random() * 100000000)}`)
          .with_endpoint(process.env.VUE_APP_AWS_IOT_ENDPOINT)
          .with_credentials(
            AWS.config.region,
            awsCredentials.aws_access_key_id,
            awsCredentials.aws_secret_access_key,
            awsCredentials.aws_session_token
          )
          .with_use_websockets()
          .build();
      const client = new mqtt.MqttClient();
      const connection = client.new_connection(config);
      await new Promise((resolve, reject) => {
        connection.on("connect", () => {
          this.loadingMsg = "Connected to AWS...";
          resolve();
        });
        connection.on("error", (error) => {
          console.log(`Connection error: ${error}`);
          reject(error);
        });
        connection.connect();
      });
      return connection;
    },
    makerSpaceSearch() {
      this.unsubscribeExistingSubscriptions();

      this.printers = []; // 프린터 목록 초기화

      this.loadingMsg = "Loading Printer List...";
      this.$axios
        .get("/printer", { params: { makerSpace: this.makerSpace } })
        .then((response) => {
          response.data.forEach((printer) => {
            this.printers.push(printer);
            this.publishToTopic({
              printer: printer,
              topic: "/CUSTOM/getStatus",
              message: {},
            });
          });
        });

      this.startRefresh();
    },
    startRefresh() {
      if (!this.refreshEnabled) {
        this.refreshEnabled = true;
        const refreshInterval = setInterval(() => {
          if (this.refreshEnabled) {
            this.refresh();
          } else {
            clearInterval(refreshInterval);
          }
        }, 30000);
      }
    },
    setPrinter(printerId, topic, message) {
      const index = this.printers.findIndex((v) => v.id === printerId);
      if (index === -1) {
        console.error(`Printer with id ${printerId} not found.`);
        return;
      }

      let updatedPrinter = { ...this.printers[index] };

      if (topic === "/CUSTOM/getStatus") {
        updatedPrinter.getStat = message;
      } else if (topic === "/POST/machine/update/refresh?name=ma3d") {
        updatedPrinter.update = message.version_info.ma3d;
      } else if (/^\/POST\/printer\/gcode\/script.*/.test(topic)) {
        this.publishToTopic({
          printer: updatedPrinter,
          topic: "/CUSTOM/getStatus",
          message: {},
        });
      } else if (topic === "/GET/server/files/list") {
        updatedPrinter.files = message.sort((a, b) => b.modified - a.modified);
      } else if (topic.includes("/server/job_queue")) {
        if (topic.includes("/start")) {
          message.queued_jobs.length == 0 &&
            this.$toasted.global.error("대기열이 비어있습니다.");
        }
        updatedPrinter.queue = message.queued_jobs;
      } else {
        console.warn(`Unhandled topic: ${topic}`);
      }

      this.$set(this.printers, index, updatedPrinter);
    },

    publishToTopic(v) {
      const reqTopic = `${v.printer.id}/req${v.topic}`;
      const resTopic = `${v.printer.id}/res${v.topic}`;
      if (!this.subscription.includes(resTopic)) {
        this.AWSConnection.subscribe(
          resTopic,
          mqtt.QoS.AtLeastOnce,
          (topic, payload_str) => {
            try {
              const payload = JSON.parse(
                new TextDecoder("utf8").decode(payload_str)
              );
              // Checking if 'payload.message' can be parsed as JSON
              let message;
              try {
                message = JSON.parse(payload.message);
              } catch (innerError) {
                console.error(
                  `Error parsing payload.message as JSON: ${innerError}`
                );
                console.log(
                  `Received non-JSON message on topic ${topic}: ${payload.message}`
                );
                // Handle non-JSON message appropriately
                return;
              }

              console.log(
                `Receive \n ID: ${topic.split("/")[0]} \n Topic: ${
                  topic.split("/res")[1]
                } `,
                message
              );
              this.setPrinter(
                topic.split("/")[0],
                topic.split("/res")[1],
                message
              );
            } catch (error) {
              console.error(`Error processing received message: ${error}`);
            }
          }
        );
        this.subscription.push(resTopic);
      }
      this.AWSConnection.publish(
        reqTopic,
        JSON.stringify(v.message),
        mqtt.QoS.AtLeastOnce
      );
      console.log(`Publish \n ID: ${v.printer.id} \n Topic: ${v.topic}`);
    },
    refresh() {
      this.printers.forEach((v) => {
        this.publishToTopic({
          printer: v,
          topic: "/CUSTOM/getStatus",
          message: {},
        });
      });
    },
    addPrinter() {
      this.$axios.put("/printer").then(() => {
        this.makerSpaceSearch();
      });
    },
    // 기존 구독 취소 메소드
    unsubscribeExistingSubscriptions() {
      if (this.AWSConnection && this.subscription.length > 0) {
        this.subscription.forEach((topic) => {
          this.unsubscribeFromTopic(topic);
        });
        this.subscription = [];
      }
    },
    // 구독 취소 메소드 정의
    unsubscribeFromTopic(topic) {
      this.AWSConnection.unsubscribe(topic).then(() => {
        // console.log(`Topic ${topic} unsubscribed`);
      });
    },
  },
};
</script>

<style scoped>
::v-deep .v-progress-linear,
::v-deep .v-progress-linear__background {
  background: var(--v-background_dark-base) !important;
  opacity: 1 !important;
}
::v-deep .v-progress-linear__determinate {
  background: linear-gradient(to right, #36d1dc, #5b86e5) !important;
  border-radius: 6px !important;
}
</style>
